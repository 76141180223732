<template>
  <div class="app-container">
    <el-row>
      <div>基本信息</div>
      <el-divider />
    </el-row>
    <el-form
      ref="reconciliationForm"
      :model="reconciliationForm"
      label-width="160px"
    >
      <el-row class="row-bg">
        <el-col :span="8">
          <el-form-item label="报价单号" prop="">
            <el-input
              v-model="reconciliationForm.offerCode"
              :placeholder="$t('page.inputPlaceholder')"
              disabled
            />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="Style" prop="">
            <el-input
              v-model="reconciliationForm.style"
              :placeholder="$t('page.inputPlaceholder')"
              disabled
            />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="供应商">
            <el-input
              v-model="reconciliationForm.vendorName"
              :placeholder="$t('page.inputPlaceholder')"
              disabled
            />
          </el-form-item>
        </el-col>

      </el-row>
      <el-row>
        <el-col :span="8">
          <el-form-item label="原报价币种">
            <el-input
              v-model="reconciliationForm.oldCurrency"
              :placeholder="$t('page.inputPlaceholder')"
              disabled
            />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="更新后报价币种">
            <el-input
              v-model="reconciliationForm.newCurrency"
              :placeholder="$t('page.inputPlaceholder')"
              disabled
            />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="更新采购订单数">
            <el-input
              v-model="reconciliationForm.updateNum"
              :placeholder="$t('page.inputPlaceholder')"
              disabled
            />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="更新采购订单总金额">
            <el-input
              v-model="reconciliationForm.newTotalPrice"
              :placeholder="$t('page.inputPlaceholder')"
              disabled
            />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="更新人">
            <el-input
              v-model="reconciliationForm.updateUser"
              :placeholder="$t('page.inputPlaceholder')"
              disabled
            />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="更新时间">
            <el-input
              v-model="reconciliationForm.updateTime"
              :placeholder="$t('page.inputPlaceholder')"
              disabled
            />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <el-row>
      <div>更新明细</div>
      <el-divider />
    </el-row>
    <el-table
      ref="multipleTable"
      v-loading="TableLoading"
      class="mb-3"
      :data="reconciliationForm.vopdVOList"
      :header-cell-style="{background:'#fafafa',color:'#606266'}"
      max-height="400px"
      style="width: 100%"
    >
      <el-table-column
        :label="$t('page.No')"
        type="index"
        width="80"
        align="center"
      >
        <template slot-scope="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column
        prop="purchaseOrderCode"
        label="采购订单"
        width="180"
        align="center"
        sortable
      />

      <el-table-column
        prop="oldCurrency"
        label="原报价币种"
        width="180"
        align="center"
        sortable
      />
      <el-table-column
        prop="oldTotalPrice"
        label="原订单金额"
        width="180"
        align="center"
        sortable
      />
      <el-table-column
        prop="newCurrency"
        label="更新后报价币种"
        align="center"
        min-width="100"
      />
      <el-table-column
        prop="newTotalPrice"
        label="更新后订单金额"
        align="center"
        min-width="150"
      />

    </el-table>

  </div>
</template>

<script>
import { vendorOfferPoById } from '@/api/scm-api'
import { formatNum } from '@/utils/index'
export default {
  data() {
    return {
      reconciliationForm: {
        vopdVOList: []
      },
      TableLoading: false
    }
  },
  created() {

  },
  mounted() {
    this._vendorOfferPoById(this.$route.query.id)
  },
  methods: {
    async _vendorOfferPoById(id) {
      try {
        this.TableLoading = true
        const { datas } = await vendorOfferPoById(id)
        this.reconciliationForm = datas
        for (const i in this.reconciliationForm) {
          if (/^[0-9]+.?[0-9]*/.test(this.reconciliationForm[i])) {
            this.reconciliationForm[i] = formatNum(this.reconciliationForm[i])
          }
        }
        this.reconciliationForm.vopdVOList = datas.vopdVOList.map(item => {
          for (const i in item) {
            if (/^[0-9]+.?[0-9]*/.test(item[i])) {
              item[i] = formatNum(item[i])
            }
          }
          return item
        })
      } finally {
        this.TableLoading = false
      }
    }
  }
}
</script>

<style scoped lang="scss">

</style>
